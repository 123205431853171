import aboutStyle from "./about.module.scss"
import img1 from "../../img/123а.png"
import img2 from "../../img/421244g.png"
import img3 from "../../img/32144f.png"

const About = () =>{
	// window.scrollTo(0, 0)
    return(
        <div className={aboutStyle.main}>
			<div className={aboutStyle.titleAbout}>КОМПАНИЯ НОРД</div>
				<div className={aboutStyle.line}/>
				<div className={aboutStyle.blockFirst}>
					<div className={aboutStyle.textAbout}>
						Организация создана в 2011 году. Основным направлением компании является производство корпусной 
						и встраиваемой мебели  по индивидуальным проектам. У нас работают специалисты с большим опытом, 
						которые создают стильную и удобную мебель для вашего дома.
					</div>
					<img src={img1} className={aboutStyle.imgAbout}/>
				</div>
				<div className={aboutStyle.blockSecond}>
					<img src={img2} className={aboutStyle.imgAbout}/>
					<div className={aboutStyle.textAbout}>
					Для производства мебели мы используем современные материалы и  мебельную фурнитуру ведущих зарубежных и 
					отечественных производителей, что является гарантией высокого качества и долговечности. На всю производимую мебель предоставляется гарантия 12 мес.
					</div>
					
				</div>
				<div className={aboutStyle.blockFirst}>
					<div className={aboutStyle.textAbout}>
					Мы производим весь спектр корпусной мебели любой сложности. У нас собственное производство в городе Кемерово. 
					Наша цель - создать домашний уют для каждого клиента, предлагая мебель, которая отражает их индивидуальный стиль и вкус.
					Работаем с физическими и юридическими лицами. Мы уверены, что вы найдете в нашем ассортименте именно то, что ищете.
					</div>
					<img src={img3} className={aboutStyle.imgAbout}/>
				</div>
		</div>
    )
}

export default About;