import viewerStyle from "./viewer.module.scss"
import { useState, useEffect } from "react";
import btnLeft from "../../img/left.png";
import btnRight from "../../img/right.png";
import {userSlice} from "../store/reducer/userSlice";
import { useDispatch, useSelector } from "react-redux";

const Viewer = (props) =>{
	const dispatch = useDispatch();
	const {seeViewer} = userSlice.actions;
	const {viewer} = useSelector(state=>state.userReducer)
	const {dataValue} = useSelector(state=>state.userReducer)
	const [slide, setSlide] = useState(0)

	console.log('ggh', dataValue)
    return(
        <div className={viewer==false?viewerStyle.mainOff:viewerStyle.main}>
			<div className={viewerStyle.blockClickExit}  onClick={()=>dispatch(seeViewer(false))}></div>
			<div className={viewerStyle.titleViewer}>{dataValue[1]}</div>
			<div className={viewerStyle.titleViewer}>{dataValue[2]}</div>
			<div className={viewerStyle.blockSlide}>
				{/* <img className={viewerStyle.btnLeft} src={btnLeft} alt="left" onClick={()=> setSlide(slide==0 ? slide : slide-1)} />
                <img className={viewerStyle.btnRight}  src={btnRight} alt="right" onClick={()=>setSlide(slide==lenght -3 ? slide : slide+1)}/>     */}
				<img className={viewerStyle.slide} src={dataValue[0]}/>
				<div className={viewerStyle.exit} onClick={()=>dispatch(seeViewer(false))}>x</div>
			</div>
		</div>
    )
}

export default Viewer;