import styleHeader from "./header.module.scss";
import { NavLink } from "react-router-dom";
import { useState} from "react";

const Header =()=>{
    
    const scrollToTop = () => {
        window.scrollTo({
          top: 2900,
          behavior: 'smooth'
        });
      };
    return(
        <div className={styleHeader.main}>
            <NavLink className={styleHeader.logo} to='/'/>
            <div className={styleHeader.blockNavigate}>
                <NavLink className={styleHeader.elemNav} to='/about'>О КОМПАНИИ</NavLink>
                <NavLink className={styleHeader.elemNav} to='/service'>УСЛУГИ</NavLink>
                <NavLink className={styleHeader.elemNav} to='/works'>НАШИ РАБОТЫ</NavLink>
                <NavLink className={styleHeader.elemNav} to='/material'>МАТЕРИАЛЫ</NavLink>
                <a className={styleHeader.elemNav} href='/#contact'>КОНТАКТЫ</a>
            </div>
            <div className={styleHeader.blockWhatTeleg}>
                        <NavLink className={styleHeader.whatsapp} to='https://wa.me/+79000562984'></NavLink>
                        <NavLink className={styleHeader.telegram} to='https://t.me/+79000562984'></NavLink>
                        <NavLink className={styleHeader.mail} to='mailto:nord-kemerovo@mail.ru'></NavLink>
            </div>
        </div>
    )
}

export default Header;