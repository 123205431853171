import materialStyle from "./material.module.scss";
import SlidBar from "../slidBar/slidBar";
import { NavLink } from "react-router-dom";

const Material = (props) =>{
    let rr
    let yy
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    };
    return(
        <div className={materialStyle.main}>
            <div className={materialStyle.title}>МАТЕРИАЛЫ</div>
            <div className={materialStyle.line}></div>
            <div className={materialStyle.blocks}>
                <NavLink className={materialStyle.navlink} to="/all/ldsp">
                <div className={materialStyle.ldsp} onClick={scrollToTop}>
                    <div className={materialStyle.elemTitle}>Лдсп</div>
                </div>
                </NavLink>
                <NavLink className={materialStyle.navlink} to="/all/stole">
                <div className={materialStyle.stole} onClick={scrollToTop}>
                    <div className={materialStyle.elemTitle}>Столешницы</div>
                </div>
                </NavLink>
                <NavLink className={materialStyle.navlink} to="/all/fasad">
                <div className={materialStyle.fasad} onClick={scrollToTop}>
                    <div className={materialStyle.elemTitle}>Фасады</div>
                </div>
                </NavLink>
                <NavLink className={materialStyle.navlink} to="/all/fartuk">
                <div className={materialStyle.fartuk}onClick={scrollToTop}>
                    <div className={materialStyle.elemTitle}>Фартуки</div>
                </div>
                </NavLink>
                <NavLink className={materialStyle.navlink} to="/all/profile">
                <div className={materialStyle.profile} onClick={scrollToTop}>
                    <div className={materialStyle.elemTitle}>Профиль купе</div>
               </div> 
               </NavLink>
           </div>
        </div>
    )
}

export default Material;