import serviceStyle from "./service.module.scss";
import crom1 from "../../img/6363blue.png";
import crom2 from "../../img/6363violet.png";
import cromstol from "../../img/stole2.png";
import ldsp from "../../img/ldsp.png";
import hdf from "../../img/hdf.png";
import dvpo from "../../img/dwpo.jpg";
import fartuk from "../../img/fartuk.png";
import stolevlag from "../../img/stolevlag.jpg";
import stole from "../../img/stolesh2.png";
import plintus from "../../img/plintus.png";
import fasad from "../../img/fasad2.png";
import raspil from "../../img/6325.jpg";
import moyka from "../../img/moyka2.png";
import petch from "../../img/petch.jpg";
import petel from "../../img/petel.jpg";

const Service = () =>{
	window.scrollTo(0, 0)
    return(
        <div className={serviceStyle.main}>
			<div className={serviceStyle.titleServ}>ПРЕДОСТАВЛЯЕМЫЕ УСЛУГИ</div>
			<div className={serviceStyle.line}/>
			<div className={serviceStyle.blockServ}>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={crom1} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}> Наклейка кромки 19мм ПВХ на ЛДСП (цена за п. м.)</div>
						<div  className={serviceStyle.elemText}>
							<ul>19*0,4 мм - 50 руб.</ul>
							<ul>19*1 мм - 75 руб.</ul>
							<ul>19*2 мм - 80 руб.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={crom2} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}> Наклейка кромки 35мм ПВХ на ЛДСП (цена за п. м.)</div>
						<div  className={serviceStyle.elemText}>
							<ul>35*0,4 мм - 100 руб.</ul>
							<ul>35*1 мм - 120 руб.</ul>
							<ul>35*2 мм - 150 руб.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={cromstol} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}> Наклейка кромки пластик на столешницу</div>
						<div  className={serviceStyle.elemText}>
							<ul> 1 п. м. - 250 руб.</ul>
							<ul> </ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={ldsp} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}>ЛДСП</div>
						<div  className={serviceStyle.elemText}>
							<ul>от 850 руб. за кв. м.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={hdf} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}>ХДФ</div>
						<div  className={serviceStyle.elemText}>
							<ul>от 300 руб. за кв. м.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={dvpo} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}>ДВПО</div>
						<div  className={serviceStyle.elemText}>
							<ul>от 250 руб. за кв. м.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={stole} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}>Столешницы (невлагостойкие)</div>
						<div  className={serviceStyle.elemText}>
							<ul>26 мм 3000*600 - от 4000 руб.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={stolevlag} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}> Столешницы (с повышенной влагостойкостью)</div>
						<div  className={serviceStyle.elemText}>
							<ul>38 мм 3000*600 - от 5500 руб.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={fartuk} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}> Стеновых панелей МДФ (фартук)</div>
						<div  className={serviceStyle.elemText}>
							<ul> 3000*600 мм - от 3800 руб.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={plintus} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}> Плинтус под цвет столешницы</div>
						<div  className={serviceStyle.elemText}>
							<ul> 3000 мм - от 550 руб.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={fasad} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}> Фасады МДФ</div>
						<div  className={serviceStyle.elemText}>
							<ul>ПВХ плёнка - от 6000 руб. кв. м.</ul>
							<ul>Пластик - от 8000 руб. кв. м.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={raspil} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}> Распил ЛДСП</div>
						<div  className={serviceStyle.elemText}>
							<ul> 1 кв. м. - 150 руб.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={moyka} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}> Вырез под мойку</div>
						<div  className={serviceStyle.elemText}>
							<ul> от 500 руб.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={petch} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}> Вырез под варочную поверхность</div>
						<div  className={serviceStyle.elemText}>
							<ul> от 500 руб.</ul>
						</div>
					</div>
				</div>
				<div className={serviceStyle.cardServ}>
					<img className={serviceStyle.image} src={petel} alt="" />
					<div className={serviceStyle.textServ}>
						<div className={serviceStyle.titleText}> Сверление отверстий, присадка под петли</div>
						<div  className={serviceStyle.elemText}>
							<ul> от 70 руб.</ul>
						</div>
					</div>
				</div>
			
			</div>
		</div>
    )
}

export default Service;