import styleFooter from "./footer.module.scss";
import { NavLink } from "react-router-dom";
import logo from '../../img/logo21.png'

const Footer =()=>{
    
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
    return(
        <div className={styleFooter.main}>
            <div className={styleFooter.blockOne}>
            <NavLink className={styleFooter.elemNav} to='/'>
                <img className={styleFooter.logo} src={logo} alt="" onClick={scrollToTop}/></NavLink>
                <div className={styleFooter.navigate}>
                    <div className={styleFooter.navHead}>НАВИГАЦИЯ ПО САЙТУ</div>
                    <NavLink className={styleFooter.elemNav} onClick={scrollToTop} to='/about'>О компании</NavLink>
                    <NavLink className={styleFooter.elemNav} onClick={scrollToTop} to='/service'>Услуги</NavLink>
                    <NavLink className={styleFooter.elemNav} onClick={scrollToTop} to='/works'>Наши работы</NavLink>
                    <a className={styleFooter.elemNav} href='/#contact'>Контакты</a>
                </div>
                <div className={styleFooter.navigate}>
                    <div className={styleFooter.navHead}>УСЛУГИ</div>
                    <NavLink className={styleFooter.elemNav} to='/service'>Изготовление мебели</NavLink>
                    <NavLink className={styleFooter.elemNav} to='/service'>Монтаж мебели</NavLink>
                    <NavLink className={styleFooter.elemNav} to='/service'>Раскрой ЛДСП</NavLink>
                    <NavLink className={styleFooter.elemNav} to='/service'>Кромление ЛДСП</NavLink>
                    <NavLink className={styleFooter.elemNav} to='/service'>Демонтаж изделий</NavLink>
                </div>
                <div className={styleFooter.navigate}>
                    <div className={styleFooter.navHead}>МАТЕРИАЛЫ</div>
                    <NavLink className={styleFooter.elemNav} to='/'>ЛДСП</NavLink>
                    <NavLink className={styleFooter.elemNav} to='/'>Столешницы</NavLink>
                    <NavLink className={styleFooter.elemNav} to='/'>Фасады</NavLink>
                    <NavLink className={styleFooter.elemNav} to='/'>Фартуки</NavLink>
                    <NavLink className={styleFooter.elemNav} to='/'>Профиль купе</NavLink>
                </div>
                <div className={styleFooter.navigate}>
                    <div className={styleFooter.navHead}>СВЯЖИТЕСЬ С НАМИ</div>
                    <NavLink className={styleFooter.elemNav} to='/'>+7-(900)-056-29-84</NavLink>
                    <NavLink className={styleFooter.elemNav} to='/'>+7-(3842)-76-99-71</NavLink>
                    <div className={styleFooter.blockWhatTeleg}>
                        <NavLink className={styleFooter.whatsapp} to='https://wa.me/+79000562984'></NavLink>
                        <NavLink className={styleFooter.telegram} to='https://t.me/+79000562984'></NavLink>
                        <NavLink className={styleFooter.mail} to='mailto:nord-kemerovo@mail.ru'></NavLink>
                    </div>
                    <div className={styleFooter.elemNavAdress}>ул.Николая Островского 7а</div>
                    <div className={styleFooter.elemNavAdress}>г.Кемерво</div>
                    
                </div>
            </div>
            <div className={styleFooter.blockTwo}>

            </div>
        </div>
        
    )
}

export default Footer;