import { createSlice } from '@reduxjs/toolkit';


export const userSlice = createSlice({
    name: 'user',
    initialState:{
        viewer: false,
        dataValue: false,
        visibleBlock: false,
    },
    reducers: {
            seeViewer(state, action){
                state.viewer= action.payload;
            },
            setDataViewer(state, action){ 
                state.dataValue= action.payload;
            },
            setVisible(state, action){ 
                state.visibleBlock= action.payload;
            },
    },
})

const {actions, reducer} = userSlice
export const {
    seeViewer,
    setDataViewer,
    setVisible
}= actions;
export default reducer;