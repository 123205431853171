import styleBody from "./body.module.scss";
import Slider from "../slider/slider";
import { NavLink } from "react-router-dom";
import TextField from '@mui/material/TextField';
import {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import {fetchContact} from "../store/reducer/contactSlice";
import {userSlice} from "../store/reducer/userSlice";

const Body =()=>{
    
    useEffect (() =>{
        if(document.location.href == 'http://localhost:3000/#contact'){
        // document.location.href="#contact"
        document.getElementById("contact").scrollIntoView(true)
    }
    },[]);
    const {setVisible} = userSlice.actions;
    const dispatch = useDispatch();
    const {register,
        handleSubmit,
        setError, 
        formState:{errors, isValid},
        } = useForm({
        defaultValues: {
            datatime: 'now()',
            name: '',
            tel: '',
        },
        mode: 'onChange',
    });

    const onSubmit = async (values) => {
        const data = await dispatch(fetchContact(values));
    };
    
    return(
        <div className={styleBody.body}>
            <Slider/>
            <div className={styleBody.title}>КОМПАНИЯ ПРЕДОСТАВЛЯЕТ УСЛУГИ</div>
            <div className={styleBody.services}>
                <div className={styleBody.manufacturing}>
                    <div className={styleBody.elemServ}>Изготовление мебели по вашим проектам</div>
                </div>
                <div className={styleBody.sawing}>
                    <div className={styleBody.elemServ}>Раскрой ЛДСП</div>
                </div>
                <div className={styleBody.edging}>
                    <div className={styleBody.elemServ}>Кромление ЛДСП</div>
                </div>
                <div className={styleBody.installation}>
                    <div className={styleBody.elemServ}>Монтаж изготовленной мебели</div>
                </div>
                <div className={styleBody.repair}>
                    <div className={styleBody.elemServ}>Ремонт</div>
                </div>
                <div className={styleBody.dismantling}>
                    <div className={styleBody.elemServ}>Демонтаж изделий</div>
                </div>
            </div>

            <div className={styleBody.line} />
            <div className={styleBody.title}>НАШИ ПРЕЙМУЩЕСТВА</div>
            <div className={styleBody.advantages}> 
                <div className={styleBody.blockAdvantages}>
                    <div className={styleBody.fast}>
                        <div className={styleBody.fastImg}/>
                        <div className={styleBody.elemAdvant}>БЫСТРЫЙ <br/>РАСЧЁТ ЗАКАЗА</div>
                    </div>
                    <div className={styleBody.urgentwork}>
                        <div className={styleBody.urgentworkImg}/>    
                        <div className={styleBody.elemAdvant}>СЖАТЫЕ <br/>СРОКИ РАБОТ</div>
                    </div>
                    <div className={styleBody.specialist}>
                        <div className={styleBody.specialistImg} />
                        <div className={styleBody.elemAdvant}>КВАЛИФИЦИРОВАННЫЕ <br/>СПЕЦИАЛИСТЫ</div>
                    </div>
                    <div className={styleBody.experience}>
                        <div className={styleBody.experienceImg}/> 
                        <div className={styleBody.elemAdvant}>ОПЫТ <br/>РАБОТЫ</div>
                    </div>
                    <div className={styleBody.designer}>
                        <div className={styleBody.designerImg}/> 
                        <div className={styleBody.elemAdvant}>УСЛУГИ <br/>ДИЗАЙНЕРА</div>
                    </div>
                    <div className={styleBody.install}>
                        <div className={styleBody.installImg}/> 
                        <div className={styleBody.elemAdvant}>МОНТАЖ <br/>ПРОДУКЦИИ</div>
                    </div>
                    <div className={styleBody.delivery}>
                        <div className={styleBody.deliveryImg}/> 
                        <div className={styleBody.elemAdvant}>ДОСТАВКА<br/><div className={styleBody.elemAdvantEl}>1</div></div>
                    </div>
                    <div className={styleBody.guarantee}>
                        <div className={styleBody.guaranteeImg}/> 
                        <div className={styleBody.elemAdvant}>ГАРАНТИЯ <br/>НА ИЗДЕЛИЯ</div>
                    </div>

                    <div className={styleBody.measure}>
                        <div className={styleBody.measureImg}/> 
                        <div className={styleBody.elemAdvant}>БЕСПЛАТНЫЕ <br/>ЗАМЕРЫ</div>
                    </div>
                    <div className={styleBody.factory}>
                        <div className={styleBody.factoryImg}/> 
                        <div className={styleBody.elemAdvant}>СОБСТВЕННОЕ<br/>ПРОИЗВОДСТВО</div>
                    </div>
                </div>
            </div>  

            <div className={styleBody.line} id='feedback'/> 
            <div className={styleBody.title}>БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ</div>
            <div className={styleBody.feedback} >
                    <div className={styleBody.girl}/>
                    <div className={styleBody.blockForm}>
                        <div className={styleBody.titleForm}>Поможем вам с выбором</div>

                        <form className={styleBody.areaTextField} onSubmit = {handleSubmit(onSubmit)}>
                        <div className={styleBody.valueName}>
                            <TextField
                                className={styleBody.textField}
                                autoComplete='off'
                                type="name"
                                label="Ф.И.О."
                                // inputProps={{style: {fontSize: 15, color: 'rgb(230, 230, 230)'}}} 
                                variant="outlined"
                                error={Boolean(errors.name?.message)}
                                helperText={errors.name?.message}
                                { ...register('name', { required: 'Имя'})} 
                            />
                        </div>
                        <div className={styleBody.valueTel}>
                            <TextField
                                className={styleBody.textField}
                                autoComplete='off'
                                type="tel"
                                label="Номер телефона"
                                // inputProps={{style: {fontSize: 15, color: 'rgb(230, 230, 230)'}}} 
                                variant="outlined"
                                multiline
                                minRows={1}
                                error={Boolean(errors.number?.message)}
                                helperText={errors.number?.message}
                                { ...register('tel', { required: 'Номер'})} 
                            />
                        </div>
                        <button className={styleBody.button} onClick={()=>dispatch(setVisible(true))}>ОТПРАВИТЬ ЗАЯВКУ</button>
                        </form>
                        <div className={styleBody.textSolution}>Нажимая кнопку вы даёте согласие на обработку и хранение персональных данных.</div>
                    </div>
            </div>

            <div className={styleBody.line} id='contact'/> 
            <div className={styleBody.contact} >
                <div className={styleBody.address}>
                    <div className={styleBody.titleAddress}>КОНТАКТЫ</div>
                    <NavLink className={styleBody.elemAddress} to='tel:+79000562984'>+7-(900)-056-29-84</NavLink>
                    <NavLink className={styleBody.elemAddress} to='tel:+73842769971'>+7-(3842)-76-99-71</NavLink>
                    <br/>
                    <br/>
                    <div className={styleBody.blockWhatTeleg}>
                        <NavLink className={styleBody.whatsapp} to='https://wa.me/+79000562984'></NavLink>
                        <NavLink className={styleBody.telegram} to='https://t.me/+79000562984'></NavLink>
                        <NavLink className={styleBody.mail} to='mailto:nord-kemerovo@mail.ru'></NavLink>
                    </div>
                    <br/>
                    <br/>
                    <div className={styleBody.elemAddress}>г.Кемерово</div>
                    <div className={styleBody.elemAddress}>ул.Николая Островского 7а</div>
                    <br/>
                    <br/>
                    
                </div>
                <iframe title="Yandex maps"  src="https://yandex.ru/map-widget/v1/?um=constructor%3Af441c4af6c4edd595fc894bfbb97aaac15dcf27584604ac7328966a881d2d7d7&amp;source=constructor" 
                width="100%" height="600" frameBorder="0">
                </iframe>
            </div>
        </div>
    )
}

export default Body;