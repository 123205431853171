import worksStyle from "./works.module.scss"
import SlidBar from "../slidBar/slidBar"
import { useEffect, useState } from "react";
import axios from "../../axios";

const src1 = 'apidatakuhni';
const src2 = 'apidatashkaf';
const src3 = 'apidatacupe';
const src4 = 'apidatatorg';
const src5 = 'apidataproch';

const Works = (props) =>{
    // window.scrollTo(0, 0)
    const [kuhni, setKuhni] = useState()
    const [shkaf, setShkaf] = useState()
    const [cupe, setCupe] = useState()
    const [torg, setTorg] = useState()
    const [proch, setProch] = useState()
    useEffect (() =>{
        axios.get(src1).then(data1 => {setKuhni(data1.data)})
        axios.get(src2).then(data2 => {setShkaf(data2.data)})
        axios.get(src3).then(data3 => {setCupe(data3.data)})
        axios.get(src4).then(data4 => {setTorg(data4.data)})
        axios.get(src5).then(data5 => {setProch(data5.data)})
    }, []);
    return(
        <div className={worksStyle.main}>
            <SlidBar urlAddres='kuhni' title='Кухни' data={kuhni}/>
            <SlidBar urlAddres='shkaf' title='Шкафы' data={shkaf}/>
            <SlidBar urlAddres='cupe' title='Шкафы-купе' data={cupe}/>
            <SlidBar urlAddres='torg' title='Торговое оборудование' data={torg}/>
            <SlidBar urlAddres='proch' title='Прочее' data={proch}/>
        </div>
    )
}

export default Works;