import './App.css';
import Body from './components/body/body';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import About from './components/about/about';
import Service from './components/service/service';
import Works from './components/works/works';
import AllWorks from './components/allWorks/allWorks';
import Material from './components/material/material';
import {Route, Routes} from 'react-router-dom';
import {userSlice} from "../src/components/store/reducer/userSlice";
import { useDispatch, useSelector } from "react-redux";

const App =(props)=> {
  const dispatch = useDispatch();
  const {setVisible} = userSlice.actions;
  const {visibleBlock} = useSelector(state=>state.userReducer)

  return (
    <div className="App">

        <Header/>
        <Routes>
           <Route exact path='/' element={<Body/>}/>
           <Route exact path='/about' element={<About/>}/>
           <Route exact path='/service' element={<Service/>}/>
           <Route exact path='/works' element={<Works/>}/>
           <Route exact path='/material' element={<Material/>}/>
           <Route exact path='/all/:name' element={<AllWorks/>}/>
        </Routes>
        <Footer/>

        <div className={visibleBlock ? "windowAgree" : "displayNone"}>
               <div className="agreeBlock">
                    <div className="textAgree">Ваша заявка принята<br/>Мы с вами свяжемся</div>
                    <div className="buttonAgree" onClick={()=>(
                       dispatch(setVisible(false)),
                       window.location.reload())}
                    >OK</div>
               </div> 
            </div>
    </div>
  )
}

export default App;
