import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios';


export const fetchContact = createAsyncThunk('apidata/api/contact/fetchContact', async(params)=>{
    const {data} = await axios.post('apidataapi/contact', params)
    return data
})


const initialState = { 
    data: null,
    status: 'loading',
}

const contactSlice = createSlice({
    name: 'contact',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(fetchContact.pending, (state) => {
            state.status = 'loading';
            state.data = null;
        });
        builder.addCase(fetchContact.fulfilled, (state, action) => {
            state.status = 'loaded';
            state.data = action.payload;
        });
        builder.addCase(fetchContact.rejected, (state) => {
            state.status = 'error';
            state.data = null;
        });
    }
})

export const selectIsContact = (state) => Boolean(state.contactReducer.data);
export const contactReducer = contactSlice.reducer; 
