import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './reducer/userSlice';
import {contactReducer} from './reducer/contactSlice';
// import {articleReducer} from './reducer/articleSlice';


const rootReducer = combineReducers({
    userReducer,
    contactReducer
    // articleReducer
})


export const setupStore = ()=>{
    return configureStore({
        reducer: rootReducer
    })
}


