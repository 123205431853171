import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://nord-kem.ru/'
});

instance.interceptors.request.use((config)=>{
    config.headers.Authorization = window.localStorage.getItem('token');
    return config;
});

export default instance;
//http://localhost:4444
//http://83.220.172.233:4444