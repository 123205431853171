import barStyle from "./slidBar.module.scss"
import btnLeft from "../../img/left.png";
import btnRight from "../../img/right.png";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Viewer from "../viewer/viewer"
import {userSlice} from "../store/reducer/userSlice";
import { useDispatch, useSelector } from "react-redux";


const SlidBar = (props) =>{
    const dispatch = useDispatch();
    const {seeViewer} = userSlice.actions;
    const {setDataViewer} = userSlice.actions;
    const [sld, setSld] = useState(0)
    const data = props.data
    const lenght = data?.length;
    const slideItem = sld * 108.5
    const url = `/all/${props.urlAddres}`
    return(
    <div className={barStyle.main}>
        <div className={barStyle.area}>
            <NavLink className={barStyle.titleWork} to={url}>{props.title}</NavLink>
            <img className={barStyle.btnLeft} src={btnLeft} alt="left" onClick={()=> setSld(sld==0 ? sld : sld-1)} />
            <img className={barStyle.btnRight}  src={btnRight} alt="right" onClick={()=>setSld(sld==lenght -3 ? sld : sld+1)}/>    
            <div className={barStyle.blockItems}>
                {data?.map(elem=>{
                   let urlVaule=elem.url
                   let titleVaule = props.title
                   const dataVaule = [urlVaule, titleVaule]
                   console.log('data', dataVaule)
                    return(
                    <div style={{ transform: `translateX(-${slideItem}%)`, margin: '0px 30px 0px 0px', transition: '1s'}}>
                        <img className={barStyle.url} src={elem.urlm} onClick={()=>(dispatch(seeViewer(true)), dispatch(setDataViewer(dataVaule)))}/>
                    </div>
                   )})} 
            </div>
        </div>
        <Viewer/>
    </div>
    )
}
export default SlidBar;