import allwStyle from "./allWorks.module.scss"
import axios from "../../axios";
import { useEffect, useState } from "react";
import Viewer from "../viewer/viewer"
import {userSlice} from "../store/reducer/userSlice";
import { useDispatch, useSelector } from "react-redux";

const AllWorks = (props) =>{
    const dispatch = useDispatch();
    const {seeViewer} = userSlice.actions;
    const {setDataViewer} = userSlice.actions;

    const [dataElem, setData] = useState()
    const url = document.location.href.slice(document.location.href.lastIndexOf('/')+1)
    
    let title
    if(url == 'kuhni'){title = 'Кухни'}
    if(url == 'shkaf'){title = 'Шкафы'}
    if(url == 'cupe'){title = 'Шкафы-купе'}
    if(url == 'torg'){title = 'Торговое оборудование'}
    if(url == 'proch'){title = 'Прочее'}

    if(url == 'ldsp'){title = 'Лдсп'}
    if(url == 'stole'){title = 'Столешницы'}
    if(url == 'fasad'){title = 'Фасады'}
    if(url == 'fartuk'){title = 'Фартуки'}
    if(url == 'profile'){title = 'Профиль купе'}
    // window.scrollTo({
    //     top: 0,
    //   });
    useEffect (() =>{
        axios.get(`apidata${url}`).then(data => {setData(data.data)})
    }, []);
    return(
        <div className={allwStyle.main}>
			<div className={allwStyle.title}>{title}</div>
            <div className={allwStyle.line}></div>
            <div className={allwStyle.blockMain}>
            {dataElem?.map(elem=>{
                let urlVaule=elem.url
                let titleVaule = props.title
                let nameVaule = elem.name
                const dataVaule = [urlVaule, titleVaule, nameVaule]
                return(
                <div className={allwStyle.block}>
                    <img src={elem.urlm} className={allwStyle.elemBlock} onClick={()=>(dispatch(seeViewer(true)), dispatch(setDataViewer(dataVaule)))}/>
                    <div>{elem.ur}</div>
                </div>
                )
            })}
            </div>
            <Viewer/>
		</div>
    )
}

export default AllWorks;