import "./slider.scss";
import btnLeft from "../../img/left.png";
import btnRight from "../../img/right.png";
import { useState, useEffect } from "react";
import { useDispatch} from "react-redux";



const Slider =()=>{

    const dispatch = useDispatch();
    const [sld, setSld] = useState(1)
    const [active, setActive] = useState(0)
    const [stopSld, setStopSld] = useState(0)
    let slde1 = `slide1${sld}`
    let slde2 = `slide2${sld}`
    let slde3 = `slide3${sld}`

    let aa =  setTimeout(()=>{
        if(sld==2){
            setActive(1)
        }
        if(active!==1){
            setSld(sld+1)
        }
    }, 5000);
        
    // if(sld==3){
    //     clearTimeout(aa)
    // }
    
    let bb =  setTimeout(()=>{
        if(sld==1){
            setActive(0)
        }
        if(active==1){
            setSld(sld !== 1 ? sld-1 : sld)
        }
    }, 5000);
    if(stopSld==1){
        clearTimeout(bb)
        clearTimeout(aa)
    }

    return(
        <div className= "main"> 
            <img className="btnLeft" src={btnLeft} alt="left" onClick={()=> {setSld(sld==1 ? sld : sld-1); setActive(4); setStopSld(1)}} />
            <img className="btnRight" src={btnRight} alt="right" onClick={()=>{setSld(sld==3 ? sld : sld+1); setActive(4); setStopSld(1)}}/>
 
            <div className= "blockSlide">
                <div className={slde1}>
                    <div className="sldText"></div>
                </div>
                <div className={slde2}>
                    <div className="sldText"></div>
                </div>
                <div className={slde3}>
                    <div className="sldText"></div>
                </div>
            </div>
        </div>
    )
}

export default Slider;